.ReactModal__Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 9999999;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Content {
  bottom: initial !important;
}

.ReactModal__Content .sankey-legend {
  margin-top: 0;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.ReactModal__Content {
  top: 50%;
  width: 85%;
  max-width: 1200px;
  transform: translate3d(0, -50%, 0) scale(0.9);
  border-radius: 8px;
  border-radius: 0;
  bottom: auto;
  box-shadow: rgba(22, 45, 60, 0.3) 0px 0px 14px 0px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow: auto;
  position: absolute;
  right: 0;
  outline: 0;

}

.ReactModalPortal > * {
  opacity: 0;
}
.ReactModal__Overlay--after-open {

  background: rgba(22, 45, 61, 0.66) !important;
  transition: opacity 0.2s ease-in, background 0.2s cubic-bezier(0.23, 1, 0.32, 1) !important;
  opacity: 1;
}

.ReactModal__Content--after-open{
  transform: translate3d(0, -50%, 0) scale(1);
  transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1), transform .35s cubic-bezier(0.23, 1, 0.32, 1);
}

.ReactModal__Overlay--after-open--before-close {
  opacity: 0;
}

